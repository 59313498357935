/* You can add global styles to this file, and also import other style files */

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: 'Fira Sans Condensed, sans-serif';
	background-color: var(--primary-background-color);
	line-height: 1.2;
	font-size: 14px;

	.mdc-outlined-text-field-label-text-color {
		color: var(--primary-foreground-color) !important;
	}

	.full-width {
		width: 100%;
	}

	.mat-content-container {
		width: 90%;
		margin: 0 auto;
		position: relative;
	}

	.mat-content-fullwidth {
		position: relative;
		padding-top: 2px;
	}

	.mat-divider {
		border-top-color: var(--primary-foreground-color);
		border-top-width: 2px !important;
		width: 90% !important;
		left: 5% !important;
		position: relative !important;
	}

	button[mat-raised-button].btn {
		background-color: var(--primary-background-color);
		color: var(--secondary-foreground-color);
	}

	.button-list {
		margin-top: 16px;
	}

	.text-center {
		text-align: center;
	}

	.loading-center {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		margin-top: 30px;
	}

	.mat-card-container {
		background-color: var(--secondary-background-color);
		border: 1px solid var(--secondary-foreground-color);
		color: var(--primary-foreground-color);
	}

	.mat-card-fullwidth {
		margin: 10px;
		/* background-color: var(--secondary-background-color); */
		border: 1px solid var(--secondary-foreground-color);
		/* color: var(--primary-foreground-color); */
		padding: 16px 0px 0px 0px !important;
	}

	.mat-card-subtitle {
		margin-bottom: 8px !important;
		/* color: var(--primary-foreground-color); */
		width: 90%;
		left: 5%;
		position: relative;
	}

	.mat-title {
		text-align: center;
		width: 90% !important;
		left: 5% !important;
		position: relative !important;
		display: flex !important;
		box-sizing: border-box;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		white-space: nowrap;
		height: 34px;
		margin: 5px 0px;
	}

	.mat-title-span {
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		width: 100%;
		pointer-events: none;
	}

	.mat-title-end {
		place-content: center flex-end !important;
	}

	a,
	a:visited,
	a:hover,
	/* a:active {
		color: var(--primary-foreground-color);
	} */

	.mat-title a {
		position: relative;
		top: 5px;
	}

	.mat-card-content form {
		padding-top: 16px;
	}

	.mat-form-field-prefix .mat-icon {
		top: 6px;
		position: relative;
	}

	.italic {
		font-style: italic;
	}

	.info-explanation {
		border-radius: 5px;
		/* background-color: var(--secondary-foreground-color); */
		margin: 10px 0px !important;
		padding: 10px;
	}

	.info-explanation .icon-explanation {
		position: relative;
		top: 3px;
		margin-right: 5px;
	}

	.info-explanation span {
		position: relative;
		top: -4px;
	}

	p {
		text-align: justify;
	}

	.mat-form-field .mat-icon {
		margin-right: 8px;
	}

	@media screen and (max-width: 959px) {
		.second-column > :first-child > .mat-card {
			margin-top: 0px;
		}
	}

	.passenger-row {
		border: 1px solid var(--primary-foreground-color);
		border-radius: 5px;
		margin-top: 10px !important;
		padding: 10px;
		place-content: center flex-end !important;
		position: relative !important;
		display: flex !important;
		box-sizing: border-box;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		height: 42px;
	}

	.passenger-row span {
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		width: 100%;
		pointer-events: none;
		padding: 10px;
	}

	.passenger-row a {
		position: relative;
		top: 2px;
	}

	.passenger-row .icon-notification {
		font-size: 14px;
		height: 14px;
		top: 2px;
		position: relative;
	}

	.missing-data {
		text-align: right;
	}

	.missing-data .mat-icon {
		width: 14px;
	}
	.number-box {
		display: inline-block;
		min-width: 54px;
		padding: 5px 5px;
		border: 2px solid var(--primary-foreground-color); /* Adjust the color as needed */
		border-radius: 8px; /* Adjust the radius as needed */
		font-size: 1.2rem; /* Adjust the font size as needed */
		text-align: center;
		margin-bottom: 5px; /* Adjust the margin as needed */
	}
}
/* Override the text field label color */
.mdc-text-field .mdc-floating-label {
	color: var(--primary-foreground-color) !important;
	background-color: var(--secondary-background-color) !important; /* Ensure the label has a background color */
	padding: 0 4px !important; /* Add padding to the label */
	z-index: 1 !important; /* Ensure the label is above the border */
}

/* Ensure the label color changes on focus */
.mdc-text-field--focused .mdc-floating-label {
	color: var(--primary-foreground-color) !important;
}

/* Ensure the label color changes on hover */
.mdc-text-field:hover .mdc-floating-label {
	color: var(--primary-foreground-color) !important;
}

/* Ensure input text color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
	color: var(--primary-foreground-color) !important;
}

.mdc-text-field {
	border: 1px solid var(--primary-foreground-color) !important; /* Set border color to white */
}

.mat-mdc-radio-button .mat-internal-form-field {
	color: var(--primary-foreground-color) !important;
}

.mat-mdc-list-item-title {
	color: var(--primary-foreground-color) !important;
}
.mat-mdc-list-item-line {
	color: var(--primary-foreground-color) !important;
}
.mat-mdc-list-item-icon {
	color: var(--primary-foreground-color) !important;
}
